<template>
    <div id="main_app">
        <b-navbar id="main_navbar" toggleable="lg" type="dark" variant="primary" fixed="top">
            <b-navbar-brand href="/">
                <b-img src="/img/icons/menu_header.png" alt="logo"/>
                {{ title }}
            </b-navbar-brand>

            <b-navbar-toggle v-if="!hideMenuItems" target="nav_collapse" id="nav_toggler"></b-navbar-toggle>

            <b-collapse v-if="!hideMenuItems" is-nav id="nav_collapse">
                <b-navbar-nav class="ml-auto">
                    <li class="d-block d-lg-none">
                        <b-row class="logo-and-company no-gutters">
                            <b-col cols="2" class="logo"><b-img left src="/img/icons/logotype.png"/></b-col>
                            <b-col>
                                <div class="company">{{ customerTradeName }}</div>
                                <div v-if="username" class="username">{{ username }}</div>
                            </b-col>
                        </b-row>
                    </li>

                    <li class="d-block d-lg-none"><hr/></li>

                    <template v-if="$store.getters.isLoggedIn">
                        <NavItem :onclick="() => $ebus.$emit('nav:jobs')" to="/jobs" :text="$t('menu.jobs')"
                            icon="/img/icons/business_center_24px.png"
                            hoverIcon="/img/icons/business_center_active_24px.png"/>

                        <NavItem :onclick="() => $ebus.$emit('nav:money-request')" to="/money-request" :text="$t('menu.moneyRequest')"
                            icon="/img/icons/account_balance_wallet_24px.png"
                            hoverIcon="i/mg/icons/account_balance_wallet_active_24px.png"/>

                        <NavItem v-if="enableHoliday" :onclick="() => $ebus.$emit('nav:settings')" to="/settings" :text="$t('menu.settings')"
                            icon="img/icons/settings_24px.png"
                            hoverIcon="img/icons/settings_active_24px.png"/>                        
                    </template>

                    <NavItem :onclick="() => $ebus.$emit('nav:about')" to="/about" :text="$t('menu.about')"
                            icon="/img/icons/info_24px.png"
                            hoverIcon="/img/icons/info_active_24px.png"/>

                    <li class="d-block d-lg-none"><hr/></li>

                    <template v-if="$route.path !== '/login'">
                        <NavItem v-if="$store.getters.isLoggedIn"
                            :onclick="() => $ebus.$emit('nav:logout')" :text="$t('menu.logout')"
                            icon="/img/icons/exit_to_app_24px.png"
                            hoverIcon="/img/icons/info_active_24px.png"/>
                        <NavItem v-else :onclick="() => $ebus.$emit('nav:login')" :text="$t('menu.login')"/>
                    </template>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <main role="main">
            <div class="container-fluid">
                <b-row align-h="center" v-if="messages">
                    <b-col>
                        <component class="message" v-for="(m, i) in messages" :is="m.type" :key="m.type + '-' + i" :message="m.data" />
                    </b-col>
                </b-row>
            </div>
            <div class="container-fluid">
                <router-view class="view"/>
            </div>
        </main>

        <footer class="site_footer">
            <div class="site_footer__copy">
                <span class="client_name">{{ customerName }}</span> <span class="copy_text" v-html="$t('footer.copy')"></span>
            </div>
            
        </footer>

        <ajax-loader :display="runningAjaxRequests" />
    </div>
</template>


<script>
import MessageDefault from '@/components/MessageDefault.vue'
import MessageModal from '@/components/MessageModal.vue'
import ErrorModal from '@/components/ErrorModal.vue'
import AjaxLoader from '@/components/AjaxLoader.vue'
import NavItem from '@/components/NavItem.vue'
import '@babel/polyfill'
import {CONFIG} from './config.js';

export default {
        data: function() {
            return {
                runningAjaxRequests: 0,
                messages: [],
                title: null,
                hideMenuItems: false,
                customerName: CONFIG.customerName,
                pageTitle: CONFIG.pageTitle,
                customerTradeName: CONFIG.customerTradeName,
                enableHoliday: CONFIG.enableHoliday,
                primaryColor: CONFIG.primaryColor
            }
        },
        components: {
            MessageDefault, ErrorModal, AjaxLoader, MessageModal, NavItem
        },
        computed: {
            username: function() {
                return this.$store.state.loggedUser;
            }
        },
        mounted: function() {
            this.$ebus.$on("nav:hide", () => this.hideMenuItems = true)
            this.$ebus.$on("nav:show", () => this.hideMenuItems = false)
            this.$ebus.$on("nav:login", () => this.$router.push({ path: '/login' }))
            this.$ebus.$on("nav:logout", () => {
                this.$store.dispatch('eraseLoggedUserSession')
                this.$router.push({ path: '/login' })
            })
            this.$ebus.$on("nav:jobs", () => this.$router.push({ path: '/jobs' }))
            this.$ebus.$on("nav:about", () => this.$router.push({ path: '/about' }))
            this.$ebus.$on("nav:settings", () => this.$router.push({ path: '/settings' }))
            this.$ebus.$on("nav:money-request", () => this.$router.push({ path: '/money-request' }))
            this.$ebus.$on("nav:reset-password-request", () => this.$router.push({ path: '/reset-password-request' }))

            this.$ebus.$on("ajax:start", () => this.runningAjaxRequests++)
            this.$ebus.$on("ajax:finished", () => this.runningAjaxRequests--)

            this.$ebus.$on("msg:new", this.addMessage)
            this.$ebus.$on("msg:clear", this.clearMessages)
            this.$ebus.$on("msg:drop", this.dropMessage)
            this.$ebus.$on("modal:close", this.dropModalMessage)

            this.$ebus.$on("title:set", (title) => this.title = title)
            this.$ebus.$on("title:reset", () => this.title = null)

            var self = this
            // add click listener for elements except inactive navigation links and hamburger icon
            document.addEventListener('click', function(event) {
                if (!event.target.matches(".nav-link:not(.active), .nav-link:not(.active) *, .navbar-toggler, .navbar-toggler *")) {
                    self.collapseMenu()
                }
            })

            this.setupPageTitle()
            this.setupPrimaryColorValue()
        },
        watch: {
            runningAjaxRequests: function(value) {
                // disable scrolling if loader is displayed
                document.body.style.overflow = value ? "hidden" : "visible";
            },
            '$route': function() {
                this.clearMessages()
                this.collapseMenu()
            }
        },
        methods: {
            clearMessages: function() {
                // drop all messages except persistent
                this.dropMessage(m => m.persistent !== true)
                // cancel persistency of persistent messages (they are persistent only in first cleaning)
                this.messages.forEach(m => m.persistent = false)
            },
            addMessage: function(message) {
                this.messages.push(message);
            },
            dropMessage: function(filter) {
                this.messages = this.messages.filter(m => !filter(m));
            },
            dropModalMessage: function() {
                this.dropMessage(m => m.type == "error-modal" || m.type == "message-modal");
            },
            setTitle: function(title) {
                this.title = title;
            },
            resetTitle: function() {
                this.setTitle(null);
            },
            collapseMenu: function() {
                if (document.getElementById("nav_collapse").classList.contains("show")) {
                    document.getElementById("nav_toggler").click()
                }
            },
            setupPageTitle() {
                document.title = this.pageTitle
            },
            setupPrimaryColorValue() {
                document.documentElement.style.setProperty('--primary', this.primaryColor);
            }
        }
    }
</script>


<style lang="scss">
@import 'src/scss/main';

.navbar {
    z-index: $z-index-navbar !important;
}

.navbar-brand {
    font-size: 25px;
    font-weight: $font-regular;
    line-height: 41px;

    img {
        margin-right: 20px;
    }
}

.nav-link {
    line-height: 24px;
    font-size: 16px;

    img {
        height: 24px;
        vertical-align:middle;
    }
}

.navbar-collapse.collapsing {
  -webkit-transition: height 0.0s;
  -moz-transition: height 0.0s;
  -ms-transition: height 0.0s;
  -o-transition: height 0.0s;
  transition: height 0.0s;
}

@mixin mobile-nav-row() {
    margin: 8px;
    padding: 10px;
    color: $dark;
}

.navbar-collapse.collapse.show {
    position: absolute;
    z-index: $z-index-navbar;
    background-color: #FFF;
    top: 0;
    left: 0;
    height: 100vh;
    width: 310px;
    padding: 0;
    box-shadow: 2px 2px 12px 0 rgba(0,0,0,0.51);
    overflow-y: auto;

    .nav-item {
        min-width: 120px;

        .nav-link {
            @include mobile-nav-row();

            border-radius: 4px;

            img.hover {
                display: none !important;
            }
        }
        .nav-link:hover, .nav-link.active {
            background-color: rgba(var(--primary), 0.12);
            color: var(--primary);

            img {
                display: none !important;
            }
            img.hover {
                display: block !important;
            }
        }

        /* no gutters */
        .row {
            margin-left: 0;
            margin-right: 0;
        }
        .col-2, .col {
            padding-left: 0;
            padding-right: 0;
        }

        @media (min-width: 768px) {
            .row {
                margin-left: -15px;
                margin-right: -15px;
            }
            .col-2, .col {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    .logo-and-company {
        @include mobile-nav-row();

        margin-top: 30px;

        .company {
            color: $light;
        }
    }

    .username {
        color: $dark;
        margin-top: 8px;
    }

    hr {
        color: $light;
    }
}

.message {
    margin-top: 10px;
}

#main_app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
main {
    flex-grow: 1;
}

#main_navbar {
    background-color: var(--primary)!important
}
.site_footer {
    color: #565656;
    padding: 20px;
}
@media screen and (min-width: 1000px) {
    .site_footer {
        text-align: center;
    }
}
</style>