import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import VueMoment from 'vue-moment';
import {messages as en} from './messages/en.js' ;
import {messages as cs} from './messages/cs.js' ;
import './api_service.js';
import './event_bus.js';
import {CONFIG} from './config.js';

Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(VueMoment);

const i18n = new VueI18n({
    locale: 'cs', // set locale
    messages: {
        en: en,
        cs: cs
    }
});

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    mounted: function() {
        // set token and its expiration from local storage if exist and user isn't logged
        if (!store.getters.isLoggedIn && localStorage.token && localStorage.token != null && localStorage.token != "null") {
            store.commit('setToken', localStorage.token)
            store.commit('setTokenExpiresIn', localStorage.expires);
            store.commit('setLoggedUser', localStorage.user);
            
            var path = store.state.tokenExpiredBackwardRoute || CONFIG.afterLoginRoute;
            router.push({ path: path });
        }

        var self = this

        // before a request is made show the progress and clear messages
        this.$api.interceptors.request.use(
            function(config) {
                if (!config.keepMessages) {
                    self.$ebus.$emit("msg:clear");
                }
                self.$ebus.$emit('ajax:start');
                return config;
            },
            function(error) {
                self.$ebus.$emit('ajax:finished');
                return Promise.reject(error);
            });

        // // before a response is returned hide progress
        this.$api.interceptors.response.use(
            function(response) {
                self.$ebus.$emit('ajax:finished');
                return response
            },
            function(error) {
                self.$ebus.$emit('ajax:finished');
                return Promise.reject(error);
            });
    }
}).$mount('#app')