import Vue from 'vue'
import Router from 'vue-router'
import store from './store';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '*',
            component: () => import('./views/NotFound.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/Login.vue')
        },
        {
            path: '/',
            redirect: { name: 'login' }
        },
        {
            path: '/reset-password/:token',
            name: 'reset-password',
            component: () => import('./views/ResetPassword.vue')
        },
        {
            path: '/reset-password-request',
            name: 'reset-password-request',
            component: () => import('./views/ResetPasswordRequest.vue')
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ './views/About.vue')
        },        
        {
            path: '/jobs',
            name: 'jobs',
            component: () => import('./views/Jobs.vue'),
            meta: { requiresAuth: true }            
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('./views/Settings.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/money-request',
            name: 'money-request',
            component: () => import('./views/MoneyRequest.vue'),
            meta: { requiresAuth: true }
        }
    ]
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }

        next('/login');
    } else {
        next();
    }
})

export default router