<template>    
    <b-col cols="11" v-if="message">
        <b-alert show :variant="message.variant || 'info'" v-html="message.text" />
    </b-col>
</template>

<script>
export default {
    props: ["message"]
}
</script>

<style scoped lang="scss">
</style>    