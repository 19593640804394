var config = {
    api: {
        baseUrl: "https://blahovec.blahovec.cloud/api/",
        tokenDomain: "emonakroni.cz",
        endpoints: {
            token: "rpc/platform_jwt_token_generate",
            forgottenPassword: "rpc/password_send_change_link",
            freeJobs: "ekr_job_self_occupable_current",
            myJobs: "rpc/ekr_job_my_current_func",
            freeJob: "rpc/ekr_job_my_do_free",
            occupyJob: "rpc/ekr_job_do_reservation",
            moneyRequest: "ekr_prepayment_request_my",
            moneyRequestDatetimes: "ekr_prepayment_term",
            resetPassword: "rpc/password_set_new",
            profile: "ekr_person_profile"
        },
        tokenExpiration: 4*3600*1000 // 4 hours
    },
    afterLoginRoute: '/jobs',
    error: {
        prefix: {
            recordNotExists: "RECORD_NOT_EXISTS",
            recordWasChanged: "RECORD_WAS_CHANGED",
            wrongLogin: "WRONG_USER_OR_PASSWORD",
            noOpenPeriod: "NO_OPEN_PERIOD",
            notRegisteredEmail: "NOT_SEND_NOT_REGISTERED_EMAIL"
        }
    },
    customerName: "EMONA KRONI s.r.o.",
    pageTitle: "Plánování směn Stafio.cz",
    customerEmail: "smeny@emonakroni.cz",
    customerPhone: "800 433 334",
    customerTradeName: "EMONA KRONI",
    jobCompanyDisplayField: "partner_name",
    weekdayFormat: "long",
    showWorkType: false,
    enableHoliday: true,
    primaryColor: '#0095DA',
    resetPasswordRequestToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiZWtyX3dzIiwiZG9tYWluIjoiZW1vbmFrcm9uaS5jeiIsImV4cCI6MjAwMDAwMDAwMH0.TyZ_HcfU2uKU9nEqV6D1JzE2e88kU6ePsxEUVJ_1i4s"
}

if (process.env.NODE_ENV == "production") {
    Object.assign(config, {
        api: {
            baseUrl: "https://blahovec.blahovec.cloud/api/",
            tokenDomain: "emonakroni.cz",
            endpoints: {
                token: "rpc/platform_jwt_token_generate",
                forgottenPassword: "rpc/password_send_change_link",
                freeJobs: "ekr_job_self_occupable_current",
                myJobs: "rpc/ekr_job_my_current_func",
                freeJob: "rpc/ekr_job_my_do_free",
                occupyJob: "rpc/ekr_job_do_reservation",
                moneyRequest: "ekr_prepayment_request_my",
                moneyRequestDatetimes: "ekr_prepayment_term",
                resetPassword: "rpc/password_set_new",
                profile: "ekr_person_profile",
            },
            tokenExpiration: 4*3600*1000 // 4 hours
        }
    });
} else {
    Object.assign(config, {
        // development configuration here
    });
}

export const CONFIG = config;