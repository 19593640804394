import Vue from 'vue';
import axios from 'axios';
import {CONFIG} from './config.js';

/**
 * AXIOS instance initialized with common options.
 */
Vue.prototype.$api = axios.create({
        baseURL: CONFIG.api.baseUrl,
        responseType: 'json',
        timeout: 0
    });

/**
 * The method performs API request.
 * 
 * @param {string} method - HTTP request method (GET, POST, PATCH, ...)
 * @param {string} endpoint - endpoint url (without domain, it's defined in $api above)
 * @param {function} callback - callback performed after succesful request, accepts one parameter 'response'
 * @param {object} options - additional axios request options
 */
Vue.prototype.$request = function(method, endpoint, callback, options) {
    options = options || {};
    options.method = method;
    options.url = endpoint;

    var self = this;

    return this.$api.request(options)
        .then(callback)
        .catch(function(error) {
            var err = null;

            if (typeof error.response === 'undefined') {
                if (!error.message) {
                    err = {type: "error-modal", data: {text: self.$t('error.unknownError')}};
                } else if (error.message == "Network Error") {
                    err = {type: "error-modal", data: {text: self.$t('error.networkError')}};
                } else {
                    alert(error.message + "\n\nStack trace:\n" + error.stack);
                    return;
                }
            } else {
                err = {type: "message-default", data: {text: error.response.data.message, httpStatusCode: error.response.status, variant: "danger"}};

                switch (error.response.status) {                    
                    case 401:
                        self.$store.commit('setTokenExpiredBackwardRoute', self.$router.currentRoute.path);
                        self.$store.dispatch('eraseLoggedUserSession');
                        self.$router.push({ path: '/login'});
                        
                        err.data.text = self.$t('error.api401');
                        err.persistent = true;
                        break;
                    case 400:
                        err.data.prefix = ""
                        err.data.text = error.response.data.message

                        var re = /^(?<prefix>[A-Z_]+):(?<text>.*)/
                        var match = re.exec(error.response.data.message)
                        if (match != null) {
                            err.data.prefix = match[2] || ""
                            err.data.text = match[3] || null
                        }

                        if (err.data.prefix != CONFIG.error.prefix.wrongLogin) {
                            err.type = "error-modal";
                        }

                        switch (err.data.prefix) {
                            case CONFIG.error.prefix.recordWasChanged:
                                err.data.text = self.$t('error.version');
                                err.data.onclick = function() {
                                    self.$loadShifts();
                                }
                                break;
                            case CONFIG.error.prefix.recordNotExists:
                                err.data.text = self.$t('error.deleted');
                                err.data.onclick = function() { self.$loadShifts(); }
                                break;
                            case CONFIG.error.prefix.wrongLogin:
                                err.data.text = self.$t('error.login');
                                break;
                            case CONFIG.error.prefix.noOpenPeriod:
                                var period = /''([^']+)/.exec(error.response.data.message)[1];
                                err.data.text = self.$t('error.closedPeriod', [period]);
                                break;
                            case CONFIG.error.prefix.notRegisteredEmail:
                                var email = JSON.parse(error.config.data).login_;
                                err.data.text = self.$t('error.notRegisteredEmail', [email]);
                                break;
                            default:
                                break;
                        }
                        
                        break;
                    default:
                        err.type = "error-modal";
                        break;
                }
            }

            self.$ebus.$emit("msg:new", err);
        });
}

/**
 * The method performs protected API request. It assumes that the token is stored in Vuex storage and this storage is accessible
 * from method context (this).
 * 
 * @param {string} method - HTTP request method (GET, POST, PATCH, ...)
 * @param {string} endpoint - endpoint url (without domain, it's defined in $api above)
 * @param {function} callback - callback performed after succesful request, accepts one parameter 'response'
 * @param {object} options - additional axios request options
 */
Vue.prototype.$protected = function(method, endpoint, callback, options) {
    if (this.$store.getters.isTokenExpired) {
        this.$store.commit('setTokenExpiredBackwardRoute', this.$router.currentRoute.path);
        this.$store.dispatch('eraseLoggedUserSession');

        this.$emit("msg:new", {type: "message-default", data:
            {text: this.$t('error.api401'), persistent: true, httpStatusCode: 401, variant: "danger"}})

        this.$router.push({ path: '/login'});
    }

    options = options || {};
    
    var headers = options.headers || {};

    Object.assign(headers, {'Authorization': "Bearer " + this.$store.state.token});

    options.headers = headers;

    return this.$request(method, endpoint, callback, options);
}
