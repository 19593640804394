<template>
    <b-row align-v="center" align-h="center" v-if="message" class="message_overlay">
        <b-col cols="10" class="inner">
            <div>
                <slot name="message-body"><div v-html="message.text"></div></slot>
            </div>

            <b-button v-if="message.onclick" variant="primary" @click="message.onclick">{{ $t("button.ok") }}</b-button>
            <b-button v-else variant="primary" @click="$ebus.$emit('modal:close')">{{ $t("button.ok") }}</b-button>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: ["message"]
}
</script>

<style scoped lang="scss">
    @import 'src/scss/main';

    .message_overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        margin: 0;
        background-color: rgba($light, .8);
        color: $dark;
        z-index: $z-index-message;
    }

    .message_overlay .inner {
        opacity: 1;
        font-size: 20px;
        font-weight: $font-bold;
    }
</style>    