import Vue from 'vue'
import Vuex from 'vuex'
import {CONFIG} from './config.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        tokenExpiresIn: null,
        tokenExpiredBackwardRoute: null,
        freeJobs: [],
        myJobs: [],
        loggedUser: null
    },
    mutations: {
        setToken (state, token) {
            state.token = token;
            localStorage.token = token;
        },
        setTokenExpiresIn (state, expires) {
            state.tokenExpiresIn = expires;
            localStorage.expires = expires;
        },
        setTokenExpiredBackwardRoute(state, route) {
            state.tokenExpiredBackwardRoute = route != '/login' ? route : CONFIG.afterLoginRoute;
        },
        setFreeJobs(state, jobs) {
            state.freeJobs = jobs;
        },
        setMyJobs(state, jobs) {
            state.myJobs = jobs;
        },
        setLoggedUser(state, user) {
            state.loggedUser = user;
            localStorage.user = user;
        }
    },
    actions: {
        eraseLoggedUserSession(context) {
            context.commit('setToken', null)
            context.commit('setTokenExpiresIn', null)
            context.commit('setLoggedUser', null)
        }
    },
    getters: {
        isLoggedIn: (state) => {
            return !!state.token;
        },
        isTokenExpired: (state) => {
            return Date.now() > state.tokenExpiresIn;
        }
    }
})