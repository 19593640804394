export const messages = {
    search: {
        button: 'Hledej'
    },
    menu: {
        home: "Domů",
        about: "O aplikaci",
        login: "Přihlásit",
        logout: "Odhlásit",
        jobs: "Směny",
        settings: "Dovolená",
        moneyRequest: "Žádost o zálohu",
        resetPassword: "Změna hesla"
    },
    button: {
        login: "Přihlásit se",
        forgottenPassword: "Zapomněl jsem heslo",
        ok: "OK",
        save: "Uložit",
        send: "Odeslat"
    },
    error: {
        shiftNotExists: "Směna {0} neexistuje",
        notFound: "Nenalezeno",
        api404: "Zdroj nenalezen",
        api401: "Platnost tokenu vypršela. Přihlašte se znovu prosím.",
        api403: "Přístup zamítnut. Požadovanou akci nelze provést.",
        timeFromAfterTimeTo: "Čas začátku směny musí být před koncem směny.",
        timeToBeforeTimeFrom: "Čas konce směny musí být po začátku směny.",
        login: "Nepodařilo se přihlásit. Chybné jméno nebo heslo.",
        shiftState: "Směna nemůže být uložena znovu se stejným statusem.",
        system: "Chyba",
        version: "Změny se nepodařilo uložit, směna byla upravena na pozadí.",
        deleted: "Změny se nepodařilo uložit, směna byla smazána na pozadí.",
        closedPeriod: "Záznam nelze upravit. Období '{0}' bylo uzavřeno.",
        notRegisteredEmail: "Nelze obnovit heslo, email '{0}' neni registrovaný.",
        empty: "{0} nesmý být prázdný",
        networkError: "Nelze navázat spojení, zkontrolujte připojení k internetu.",
        unknown: "Při zpracování požadavku došlo k chybě, opakujte akci pozdeji."
    },
    login: {
        password: "Heslo",
        email: "E-mail"
    },
    about: {
        support: "Podpora",
        phone: "Telefon",
        version: "Verze aplikace"
    },
    jobs: {
        tab: {
            my: "Plánované",
            free: "Volné"
        },
        confirm: {
            free: {
                text: "Zrušit obsazení směny?",
                yes: "Zrušit obsazení",
                no: "Ne"
            },
            occupy: {
                firstText: "Skutečně si přejete obsadit směnu",
                secondText: "Obsazení je závazné. Odhlásit se můžete v Plánovaných směnách.",
                yes: "Ano, obsadit",
                no: "Ne",
                startTime: "Čas příchodu:",
                endTime: "Čas odchodu:"
            }
        },
        success: {
            free: "Obsazení směny bylo zrušeno.",
            occupy: "Směna byla obsazena."
        },
        myJobsEmpty: "Žádné obsazené směny",
        freeJobsEmpty: "Žádné volné směny k obsazení"
    },
    settings: {
        user: "Uživatelské jméno",
        phone: "Telefon",
        noPhoneError: "Telefon nesmí být prázdný.",
        vacationDate: {
            label: "Dovolená do",
            placeholder: "Zadejte datum v budoucnosti",
            success: "Datum dovolené byl uložen",
            cancel: "Datum dovolené byl smazán",
            invalid: "Zadaná hodnota není datum v budoucnosti",
        }
    },
    moneyRequest: {
        datetime: "Datum zaslání na účet",
        date: "Datum zaslání",
        time: "Čas zaslání",
        amount: "Požadovaná částka",
        currency: "Kč",
        confirm: {
            yes: "Ano, odeslat",
            no: "Ne",
            text: "Odeslat požadavek na výplatu zálohy?<br>" +
                " Požadavek nelze v aplikaci měnit. Jakékoli změny jsou možné pouze po telefonické domluvě s agenturou."
        },
        success: "Žádost byla odeslána. Záloha se v uvedený termín posílá na účet. Změnu je možné hlásit pouze telefonicky.",
        error: {
            empty: "@:(moneyRequest.amount) nesmí být prázdná"
        },
        am: "dopoledne",
        pm: "odpoledne"
    },
    weekDays: {
        monday: 'Po',
        tuesday: 'Út',
        wednesday: 'St',
        thursday: 'Čt',
        friday: 'Pá',
        saturday: 'So',
        sunday: 'Ne'
    },
    resetPassword: {
        password: "Nové heslo",
        repeat: "Potvrďte heslo",
        success: "Heslo bylo změněno",
        noPasswordError: "Heslo nesmí být prázdné",
        notEqualPasswordsError: "Hesla v obou polích musí být stejná",
        noEmailError: "Email nesmí být prázdný",
        requestSent: "Odkaz na resetování hesla byl odeslán. Zkontrolujte Váš e-mail.",
        email: "E-mail",
        emailDescription: "Zadejte e-mail, který používáte jako přihlašovací jméno, bude na něj odeslána zpráva s odkazem na resetování hesla.",
        invalidEmailError: "Zadaný řetězec není validní email"
    },
    notFound: {
        text: "Omlouváme se! Stránka, kterou jste hledali, nebyla nalezena."
    },
    datepicker: {
        ok: "Vybrat",
        cancel: "Zrušit",
        clear: "Smazat",
        placeholder: "Dovolená není zadána, <u>Přidat</u>"
    },
    footer: {
        copy: "používá <a href='https://www.stafio.cz/' target='_blank'>HR systém Stafio.cz</a> pro plánování směn a automatizaci komunikace s pracovníky."
    }
}