<template>
    <MessageModal :message="message">
        <template v-slot:message-body>{{ $t('error.system') }}: {{ message.text }}</template>
    </MessageModal>
</template>

<script>
import MessageModal from '@/components/MessageModal.vue'

export default {
    props: ["message"],
    components: { MessageModal }    
}
</script>

<style scoped lang="scss">
    @import 'src/scss/main';

    .message_overlay .inner {
        color: $danger;
    }
</style>    