<template>
    <b-nav-item :to="to" @click="onclick">
        <b-row>
            <b-col cols="2">
                <b-img left v-if="icon" :src="icon"/>
                <b-img left v-if="hoverIcon" :src="hoverIcon" class="hover d-block d-md-none"/>
            </b-col>
            <b-col class="menu-link">{{ text }}</b-col>
        </b-row>
    </b-nav-item>
</template>

<script>
export default {
    props: {
        onclick: {type: Function, required: true},
        icon: String, 
        hoverIcon: String, 
        text: {type: String, required: true},
        to: {type: String}
    }
}
</script>

<style scoped lang="scss">
    .menu-link {
        margin-top: 3px;
    }
</style>