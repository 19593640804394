export const messages = {
    search: {
        button: 'Search tenders'
    },
    menu: {
        home: "Home",
        about: "About",
        login: "Login",
        logout: "Logout",
        jobs: "Jobs",
        settings: "Vacations",
        resetPassword: "Reset password"
    },
    button: {
        login: "Login",
        forgottenPassword: "I forgot my password",
        ok: "OK",
        save: "Save",
        send: "Send"
    },
    error: {
        shiftNotExists: "Shift {0} doesn't exist",
        notFound: "Not found.",
        api404: "Source not found.",
        api401: "Token expired. Please login again.",
        api403: "Permission denied. Unable to perform a required action.",
        timeFromAfterTimeTo: "Shift start time shall preceed shift end time.",
        timeToBeforeTimeFrom: "Shift end time shall follow shift end time.",
        login: "Login failed. Incorrect login name or password.",
        shiftState: "The shift can't be saved twice with the same status.",
        system: "Error",
        version: "Changes couldn't be saved, the shift was updated on background.",
        deleted: "Changes couldn't be saved, the shift was deleted on background.",
        closedPeriod: "Record cannot be updated. The period '{0}' was closed.",
        notRegisteredEmail: "Unable to recover the password, email '{0}' is not registered.",
        empty: "{0} can not be empty",
        networkError: "Unable to establish a connection, check the internet connection."
    },
    login: {
        password: "Password",
        email: "E-mail"
    },
    about: {
        support: "Support",
        phone: "Phone",
        version: "Application version"
    },
    jobs: {
        tab: {
            my: "Planned",
            free: "Free"
        },
        confirm: {
            free: {
                text: "Cancel shift occupacy?",
                yes: "Cancel occupacy",
                no: "No"
            },
            occupy: {
                text: "Occupacy is bidding. You can opt out in Planned shifts. Do you really want to occupy?",
                yes: "Yes, occupy",
                no: "No"
            }
        },
        success: {
            free: "Shift occupacy was cancelled.",
            occupy: "Shift was occupied."
        },
        myJobsEmpty: "No occupied shifts",
        freeJobsEmpty: "No free shifts to cast"
    },
    settings: {
        user: "User name",
        phone: "Phone",
        noPhoneError: "Phone can not be empty.",
        vacationDate: "Vacation to",
        vacationDatePlaceholder: "Enter a date in the future"
    },
    moneyRequest: {
        datetime: "Date and time of send",
        date: "Date of send",
        time: "Time of send",
        amount: "Required amount",
        currency: "CZK",
        confirm: {
            yes: "Yes, send",
            no: "No",
            text: "Send a request for payment of an advance?<br>" +
                "The requirement can not be changed in the application. Any changes are possible only by telephone agreement with the agency."
        },
        success: "The request was sent. Money will be sent on the given date using bank transfer. The change can be reported only by phone.",
        error: {
            empty: "@:(moneyRequest.amount) can not be empty"
        },
        am: "am",
        pm: "pm"
    },
    resetPassword: {
        password: "New password",
        repeat: "Confirm password",
        success: "The password has been changed",
        noPasswordError: "Password can not be empty",
        notEqualPasswordsError: "Passwords in both fields must be the same",
        noEmailError: "Email can not be empty",
        requestSent: "Password reset link sent. Check your email.",
        email: "E-mail",
        emailDescription: "Email with reset password link will be sent to this address.",
        invalidEmailError: "The specified string is not a valid email"
    },
    notFound: {
        text: "Sorry! The page you were looking for could not be found."
    },
    footer: {
        copy: "use <a href='https://www.stafio.cz/' target='_blank'>HR system Stafio.cz</a> for shift planning and automation of communication with employees."
    }
}