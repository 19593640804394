<template>
    <b-row align-v="center" align-h="center" v-if="display" class="ajax_loader_outer">
        <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                    <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"/>
                    </path>
                </g>
            </g>
        </svg>
    </b-row>
</template>

<script>
export default {
    props: ["display"]
}
</script>

<style scoped lang="scss">
    @import 'src/scss/main';

    .ajax_loader_outer {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        margin: 0;
        background-color: rgba($light, .5);
        color: var(--primary);
        z-index: $z-index-ajax-loader;
    }

    .ajax_loader_outer svg {
        stroke: var(--primary);
        width: 80px;
        height: 80px;
    }
</style>    